import React from "react";

function Footer() {
  return (
    <div className="footer">

      <footer className="py-2 fixed-bottom">
      <p className="m-0 p-2">


          </p>
      </footer>
    </div>
  );
}

export default Footer;
